import { Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import EasyMDE from 'easymde';

import '../../node_modules/easymde/dist/easymde.min.css';

Alpine.directive('easymde', (el, { expression }, { evaluate }) => {
    let extraOptions = evaluate(expression);
    if (typeof extraOptions === 'object' && !Array.isArray(extraOptions) && extraOptions !== null) {
        extraOptions = {};
    }

    let editor = new EasyMDE({
        toolbar: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'heading-1',
            'heading-2',
            'heading-3',
            '|',
            'quote',
            'unordered-list',
            'ordered-list',
            '|',
            'preview',
            'side-by-side',
            'fullscreen',
        ],
        forceSync: true,
        indentWithTabs: false,
        spellChecker: false,
        ...extraOptions,
        autoDownloadFontAwesome: false,
        element: el,
    });

    editor.codemirror.on('change', function () {
        el.dispatchEvent(new Event('input'));
    });
});
